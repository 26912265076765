@import "../../../../styles/variables.scss";

.listDivider {
    margin-bottom: calc(20px + 2vw);

    .list {
        @include container(2.2);
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(calc(260px + 4vw), 1fr));
        grid-column-gap: 20px;
    }
}
