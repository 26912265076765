@use "sass:map";
@import "../variables.scss";

.list {
    @include container(2.2);
    margin: 0 auto;
    max-width: 1400px;
    & > div {
        margin-bottom: calc(20px + 2vw);
    }
}

.otherlist {
    @include tileList;
}

.smallForm {
    @include container(7.2);
}

.searchWrapper {
    margin-top: calc(-20px - 2vw);
}

