@use "sass:map";
@import "../../../../styles/variables.scss";

.priceListWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    span {
        padding: 5px 10px;
    }
}   