@use "sass:map";
@import "../../../../styles/variables.scss";

.priceBox {
    border: 1px solid $primary;
    width: clamp(140px, 20vw, 240px);
    text-align: center;
    margin: 10px 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 500;
    .time, .price {
        padding: 8px;
    }
    .time {
        color: $primary;
    }
    .price {
        background: $primary;
        color: $light1;
    }
}
