@import "../../../../styles/variables.scss";

$photoSize: clamp(100px, 30vw, 150px);

.carCardSmall {
    border-radius: $radius;
    background: 1;
    margin-bottom: 15px;
    box-shadow: 0px 2px 5px -4px #000;
    display: flex;
    flex-direction: row;
    // max-width: 500px;
    position: relative;

    &.vertical {
        flex-direction: column;

        .photo {
            width: 100%;
            height: 300px;
        }

        .paramsColumn {
            padding: 0.4vh 5px;
            font-size: 1rem;
        }
        
        .icon {
            margin-bottom: 0.5vh;
        }

        .controller {
            margin-top: 10px;
        }

        .priceWrapper {
            position: absolute;
            right: 0;
        }
    }
}

.photo {
    border-radius: $radius;
    width: $photoSize;
    height: $photoSize;
    object-fit: cover;
}

.body {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.date {
    font-size: 0.7rem;
    margin-bottom: 5px;
}

.name {
    color: $primaryLight;
    font-weight: 600;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid $dark;
    width: 100%;
}

.paramsList {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.paramsColumn {
    display: flex;
    min-width: 24%;
    flex-direction: column;
    align-items: center;
    padding: 0.4vh 5px;
    font-size: calc(0.6rem + 0.5vw);
}

.icon {
    margin-bottom: 0.2vh;
    font-size: 26px;
}

.iconSvg {
    width: 26px;
    height: 26px;
}

.controller {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0;
}
