@import "../../../../styles/variables.scss";

.blogListItem {    
    margin-bottom: 10px;
    .blogItemPhoto {
        height: 300px;
        position: relative;
        border-radius: $radius;
        overflow: hidden;

        .blogItemTitle {
            position: absolute;
            width: 100%;
            bottom: 0;
            font-size: 2rem;
            font-weight: 700;
            padding: 40px 10px 10px 10px;
            color: #fff;
            background: linear-gradient( rgba(0,0,0,0.001) 0%, rgba(0,0,0,0.2) 35%, rgba(0,0,0,0.8) 100%);
        }

        img {
            object-fit: cover;
        }
    }

    .blogItemIntro {
        padding: 5px;
        text-align: justify;
        line-height: 1.3;
    }
}