.carContactCard {
    .address {
        font-size: 1.1rem;
        margin: 3px 0 5px 0;
    }
    .logoWrapper {
        display: flex;
        justify-content: center;
        .logo {
            max-width: calc(180px + 2vw);
            max-height: calc(80px + 5vh);
            margin: 0 auto ;
        }
    }  
}