@import "../../../../styles/variables.scss";

.parameterRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px 0;
    border-bottom: 1px solid $light2;
    font-size: 0.9rem;
    font-weight: 300;
}
