@use "sass:map";
@import "../../../../styles/variables.scss";

.priceIntroWrapper {
    background: $successColor;
    padding: 18px 10px 8px 10px;
    width: min-content;
    border-radius: 0 $radius 0 0;
    color: $fontLight;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    height: 100%;

    @media screen and (max-width: map.get($breakpoints, "md")) {
        padding: 18px 7px 8px 7px;
        letter-spacing: -0.5px;
    }
}

.priceFrom,
.priceTimeUnit {
    font-size: 1rem;
    white-space: nowrap;
    font-weight: 300;
}

.priceAmount {
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 700;
}

.priceTimeUnit {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1px;
}

.priceFrom {
    margin-top: -10px;
}

.priceIntroWrapper.big {
    .priceFrom,
    .priceTimeUnit {
        font-size: 0.9rem;
    }

    .priceAmount {
        font-size: 2rem;
    }

    @media screen and (max-width: map.get($breakpoints, "md")) {
        .priceAmount {
            font-size: 1.9rem;
        }
    }
}
