@use "sass:map";
@import "../../../../styles/variables.scss";

$break: "md";
$cardSpan: 20px;
$headerHeight: 56px;

.carCard {
    @extend %card;
    min-height: 200px;
}

.mobileTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $headerHeight;
    @media screen and (min-width: map.get($breakpoints, $break)) {
        display: none;
    }
}

.companyLogo {
    height: $headerHeight;
    max-width: 50%;
    object-fit: contain;

    @media screen and (min-width: map.get($breakpoints, $break)) {
        margin-left: $cardSpan;
    }
}

.companyName {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-size: 1.2rem;
    min-height: 50px;
}

.carPhoto {
    width: 100%;
    object-fit: contain;
    max-height: 400px;
    @media screen and (max-width: map.get($breakpoints, $break)) {
        max-height: 300px;
        min-height: 300px;
        object-fit: cover;
    }
}

.contentWrapper {
    @media screen and (min-width: map.get($breakpoints, $break)) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .carPhoto {
            width: 100%;
            object-fit: cover;
            min-height: calc(300px + 3vw);
        }
    }
}

.footer {
    height: 12px;
    background: $primaryLight;
    border-radius: 0 0 $radius $radius;
}

.btns {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
        margin: 0 4px;
    }
}

.carDetails {
    margin: 10px $cardSpan;
    .carTitle {
        font-size: 1.2rem;
        font-weight: 600;
        padding-bottom: 5px;
        margin-bottom: 5px;

        .carTitleYOP {
            font-weight: 300;
            margin-left: 4px;
            font-size: 1rem;
        }

        @media screen and (max-width: map.get($breakpoints, $break)) {
            border-bottom: 2px solid $dark;
        }
    }
}

.rightColumn {
    display: flex;
    flex-direction: column;

    .rightColumnHeader {
        height: $headerHeight;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: map.get($breakpoints, $break)) {
            display: none;
        }
    }

    .rightColumnBody {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
    }

    @media screen and (min-width: map.get($breakpoints, $break)) {
        // margin-top: 32px;
        width: 70%;
    }
}
.logoInCard {
    display: none;
    @media screen and (min-width: map.get($breakpoints, $break)) {
        display: block;
        position: absolute;
        left: 0;
        top: -32px;
    }
}

.leftColumn {
    @media screen and (min-width: map.get($breakpoints, $break)) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
}